import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/feiten-over-diarree/"> Feiten over diarree </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips voor behandeling
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                  Oorzaken van diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/stress-en-diarree/">
                  Stress en diarree
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                      Hoge werkdruk
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                      Examensituaties
                    </a>
                  </li>
                </ul>
              </li>
              <li className="active">
                <a href="/feiten-over-diarree/buikgriep/">
                  Virale of bacteriële infectie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                  Diarree tijdens de menstruatie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/chronische-diarree/">
                  Chronische Diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/eenartsraadplegen/">
                  Een arts raadplegen?
                </a>
              </li>
              <li className="last">
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                  Gezonde eetgewoonten
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/">
                      Voedselallergieën
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/">
                      Voedselvergiftiging
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-28">
            <h1>Virale of bacteriële infectie</h1>
            <div className="img">
              <img
                src="/assets/files/pages/buikgriep.jpeg"
                width="701"
                height="289"
                alt="Virale of bacteriële infectie"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Virale of bacteriële infectie als oorzaak bij diarree.</h2>
              </div>
              <div className="nine columns">
                <p>
                  <strong>Diarree</strong>, die gepaard gaat met misselijkheid
                  of braken, hoofdpijn en lichte koorts, is vaak een aanwijzing
                  van een infectie in het spijsverteringskanaal. Dit wordt dan
                  een bacteriële of virale <strong>gastro-enteritis</strong>
                  &nbsp;genoemd. Een gastro-enteritis gaat na een paar dagen
                  meestal wel over, zeker indien ze van virale oorsprong is.
                </p>
                <p>
                  Bijzondere risicogroepen zoals kleine kinderen, zieke of
                  oudere mensen hebben echter medische hulp nodig. Je moet een
                  arts raadplegen als je koorts krijgt of bloed in je ontlasting
                  ontdekt.
                </p>
              </div>
            </div>
            <h3>De hoofdoorzaken van bacteriële of virale gastro-enteritis</h3>
            <ul>
              <li>
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/">
                  Voedselvergiftiging
                </a>
                &nbsp;of drinken van verontreinigd water (wordt bij reizen naar
                het buitenland ook '
                <a href="/diarree-op-reis/">reizigersdiarree</a>' genoemd)
              </li>
              <li>
                Infectie met het{" "}
                <a href="/feiten-over-diarree/norovirus/">norovirus</a>&nbsp;of
                andere virussen (treden vooral in de winter op)
              </li>
            </ul>
            <p>
              Jammer genoeg kan een gastro-enteritis zeer besmettelijk zijn.
              Bereid daarom best geen voedsel in geval je een infectie hebt en
              was na elke stoelgang grondig je handen met een ontsmettende zeep.
              Blijf na het einde van de <strong>diarree</strong> of het braken
              liefst nog 48 uur lang thuis.
            </p>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
